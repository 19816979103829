.GuidelinesTitle {
  font-family: "Figtree", sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.GuidelinesDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3D4837;
}

.GuidelinesSubTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #263322;
}

.GuidelineItem {
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-color: #6C7360;
  background-color: #FBFAF2;
  border-radius: 8px;
  gap: 16px;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
}

.GuidelineCommunityItem {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: #FAF7E6;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}