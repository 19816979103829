.SettingsHeaderLogIn {
  display: flex;
  justify-content: space-between;
}

.SettingsHeaderCaretLeft {
  font-size: 16px;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  cursor: pointer;
}

.SettingsHeaderCardHeaderContainer {
  background-color: '#FAF7E6';
  display: flex;
  width: 100%;
  height: 126px;
}

.SettingsHeaderCardClose {
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 14px;
  border-right: 1px solid #263322;
  border-bottom: 1px solid #263322;
  background-color: #FAF7E6;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #263322;
}

.SettingsHeaderCardClose > div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.SettingsHeaderCardTitleDiv {
  flex: 1;
  border-bottom: 1px solid #263322;
  position: relative;
  overflow: hidden;
}

.SettingsHeaderCardBackgroundTitle {
  position: absolute;
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-size: 25px;
  font-weight: 600;
  top: 0;
  line-height: 33px;
  background-color: #FAF7E6;
}

.SettingsHeaderCardBackgroundTitle > span {
  opacity: 0.5;
  color: #E2DEC6;
}

.SettingsTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  color: #263322;
  z-index: 1;
  font-family: "Martian Mono", monospace;
  line-height: 36px;
  padding-bottom: 10px;

  span {
    font-size: 30px;
    font-weight: 600;
    padding: 0px 0px 0px 12px;
    z-index: 1;
    background-color: transparent;
  }
}
