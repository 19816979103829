.CommentContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;

  border-color: #263322;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;

  background-color: #fbfdfe;
}

@media (max-width: 720px) {
  .CommentContainer {
    border-left-width: 0px;
    border-right-width: 0px;
  }
}

.CommentReplyContainer {
  background-color: #faf4da;
  border-bottom-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #263322;
  padding-bottom: 0px;
}

.CommentAvatar {
  width: 70px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.CommentBodyContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 10px;
  padding-top: 10px;
}

.CommentBody {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-left: 10px;
  width: 100%;
}

.CommentLikes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.CommentAuthorText {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
}

.CommentMessageText {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.CommentTimeText {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #848874;
}

.CommentRepliesContainer {
  margin-left: 15px;
  margin-right: 15px;
  /* border-left-width: 1px;
  border-right-width: 1px; */
  border-color: #263322;
}

.CommentShowRepliesButton {
  display: flex;
  width: 100%;
  flex: 1;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #263322;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  background-color: #faf7e8;
  height: 40px;
}

.CommentShowRepliesContainer {
  opacity: 0;
  height: 0;
}

.CommentShowRepliesContainerVisible {
  opacity: 1;
  transition: opacity 1.5s ease-out;
  height: auto;
}

.CommentLike {
  width: 28px;
  height: 28px;
  border-radius: 20px;
}

.CommentLikeSelected {
  border-width: 1px;
  border-color: #263322;
}

.CommentLikeButtonContainer {
  display: "flex";
  flex-direction: column;
  width: 100%;
  flex: 1;
  background-color: transparent;
}
