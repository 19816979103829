.CompleteProfileContainerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: #263322;
  border-width: 1px;
  border-radius: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  padding-bottom: 10px;
}

.CompleteProfileButtonContainer{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  align-items: center;
}

.CompleteProfileEarnMoreButton {
  border-width: 1px;
  border-radius: 100px;
  border-color: #263322;
  background-color: #FBFAF2;
  box-shadow: 2px 2px 0px 0px #263322;
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  font-family: "Martian Mono", monospace;
  height: 2rem;
}

.CompleteProfileCountButton {
  background-color: #FBFDFE !important;
  height: 1.5rem !important;;
}

.CompleteProfileImageContainer{
  /* background-color: #E2DEC6; */
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  min-height: 280px;
}

.CompleteProfileBackground {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}

.TicketContainer{
  justify-content: center;
  display: flex;
  z-index: 1;
  position: relative;
}

.CompleteProfileTitle {
  font-family: "Figtree", sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.CompleteProfileDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3D4837;
}

.CopyButton {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #FBFAF2;
  box-shadow: 2px 2px 0px 0px #263322;
  margin-bottom: 3px;
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
  height: 40px;
}

.CopyButton:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}

.CompleteProfileTrackerTitle {
  font-family: "Figtree", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #263322;
}

.CompleteProfileTrackerContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.TrackerCompleteProfileItemContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #263322;
  background-color: #FCF3FE;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.TrackerCompleteProfileDescriptionContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.TrackerCompleteProfileName {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  color: #263322;
}

.TrackerCompleteProfileDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #6C7360;
}

.CompleteProfileDrawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
}

.CompleteProfileDrawerGrabberBar {
  background:  #9C9E88;
  width: 40px;
  height: 4px;
  border-radius: 100px;
}

.CompleteProfileDrawerFlex {
  display: flex;
  width: 100%;
  align-items: center;
  height: 70px;
}

.CompleteProfileDrawerFlex .CompleteProfileDrawerTitle {
  text-align: center;
  width: 100%;
}

.CompleteProfileDrawerTitle {
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.CompleteProfileDrawerSubtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #263322;
}

.CompleteProfileHeaderSteps {
  display: flex;
  width: 100%;
}

.CompleteProfileHeaderStep {
  margin: 24px 0;
  flex: 1;
  height: 6px;
  background:  #FAF7E6;
  border-width: 1px;
  border-style: solid;
  border-color:  #263322;
}

.CompleteProfileHeaderStep.CompleteProfileHeaderStepActived {
  background:  #9AECBF;
}

.CompleteProfileSteps {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 220px;
  justify-content: center;
}

.CompleteProfileLinkedinLogo {
  font-size: 20px;
}

.CompleteProfileLinkedinDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:  #6C7360;
}

.CompleteProfileFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.CompleteProfileFooter div {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  color:  #70406F;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.CompleteProfileFooter .disabled {
  opacity: 0.25;
}

.CompleteProfileAvatar {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}

.CompleteProfileAvatar > div {
  position: relative;
}

.CompleteProfileFloatButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.CompleteProfileRole {
  display: flex;
  gap: 12px;
}

.CompleteProfileRole .ant-radio {
  display: none;
}

.CompleteProfileStepRadioItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-color: #263322;
  background-color: #FBFAF2;
  padding: 24px;
}

.CompleteProfileStepRadioItemSelected {
  border-color: #2f4529;
  border-width: 2px;

}

.CompleteProfileStepRadioItem > svg {
  flex: 1;
  font-size: 40px;
  padding-bottom: 24px;
}

.CompleteProfileStepRadioItemTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color:  #263322;
}

.CompleteProfileStepRadioItemDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:  #6c7360;
  text-align: center;
}

.CompleteProfileForm {
  text-align: left;
}

.CompleteProfileForm > label {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #263322;
}

.CompleteProfileNotification {
  position: absolute;
  bottom: -200px;
}

.CompleteProfileNotification.open {
  bottom: 0px;
}

.ant-notification-notice-wrapper {
  box-shadow: none !important;
}
