.TredingFooterContainer {
  display: flex;
  color: #848874;
  background-color: transparent;
  width: 100%;
  justify-content: space-between;
  height: 40px;
}

.TredingFooterContainer button {
  font-family: Martian Mono;
  font-size: 13px;
  font-weight: 700;
}

.TredingFooterAction {
  border: 1px solid #263322;
  border-top: none;
  border-left: none;
  border-radius: 0 0 4px 0;
  background-color: #FBFDFE;
  opacity: 1 !important;
}

.TredingFooterLikeComment {
  display: flex;
  flex: 1;
  border: 1px solid #263322;
  border-top: none;
  border-radius: 0 0 0 16px;
  background-color: #FBFDFE;
}