.SignUpStepsContainer {
  padding: 32px 24px;
  max-width: 720px;
  margin: auto;
}

.SignUpStep {
  display: flex;
  flex-direction: column;
}

.SignUpStepImg {
  padding-bottom: 12px;
}

.SignUpStepTitle {
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.SignUpStepDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #263322;
  padding-bottom: 32px;
}

.SignUpStepInfo {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #263322;
}

.SignUpStepsContainer .ant-select-selection-placeholder,
.SignUpStepsContainer input::placeholder {
  color: #9c9e88;
}

.SignUpStepsContainer .ant-form-item-label > label {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #5b2d5b;
}

.SignUpStepsContainer .ant-radio-wrapper {
  padding: 16px;
  width: 100%;
  border: 1px solid transparent;
}

.SignUpStepsContainer .ant-radio-inner {
  border: 1px solid var(--Light-Mode-Lilac-lilac-950, #5b2d5b) !important;
  background: transparent !important;
}

.SignUpStepsContainer .ant-radio-wrapper-checked .ant-radio-inner::after {
  background: var(--Light-Mode-Lilac-lilac-950, #5b2d5b);
}

.SignUpStepReset {
  font-family: Martian Mono;
  font-size: 11px;
  font-weight: 700;
  color: var(--Light-Mode-Lilac-lilac-900, #70406f);
  align-self: flex-end;
  width: fit-content;
}

.SignUpStepsContainer .ant-form-item-required::after,
.SignUpStepsContainer .ant-form-item-required::before {
  content: "" !important;
}

.SignUpStepsContainer .ant-form-item-label > label::after {
  content: "(optional)";
  visibility: initial;
}

.SignUpCard {
  width: 100%;
  position: relative;
}

.SignUpCardHeader {
  height: 40px;
  width: fit-content;
  border: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
  border-width: 1px 1px 0 1px;
  border-radius: 16px 16px 0 0;
  box-shadow: 2px 0px 0px 0px #263322;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
}

.SignUpCardHeaderText {
  padding: 12px;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  display: flex;
  gap: 12px;
  align-items: center;
  padding-right: 36px;
}

.SignUpCardBody {
  border: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
  border-radius: 16px;
  box-shadow: 2px 0px 0px 0px #263322;
  position: relative;
  top: -1px;
  padding-top: 48px;
  background-color: #ffffff;
  border-top-left-radius: 0;
}

.SignUpCardBodyNotFound {
  background: var(--Light-Mode-Neutral-neutral-150, #faf7e6);
  border: 1px dashed var(--Light-Mode-Neutral-neutral-950, #263322);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 322px;
  border-radius: 16px;
  gap: 8px;
}

.SignUpCardBodyNotFoundText {
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 700;
  color: var(--Light-Mode-Neutral-neutral-800, #6c7360);
}

.SignUpCardFullName {
  background: var(--Light-Mode-Lime-lime-150, #ecfabc);
  border: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
  border-width: 1px 0px 1px 0px;
  display: flex;
  align-items: center;
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  padding: 10px;
}

.SignUpCardInfo {
  display: flex;
  background: var(--Light-Mode-Neutral-neutral-150, #faf7e6);
  flex-wrap: wrap;
}

.SignUpCardInfoItem {
  padding: 16px;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
}

.SignUpCardInfoItem h3 {
  font-size: 14px;
  font-weight: 700;
  color: var(--Light-Mode-Neutral-neutral-900, #3d4837);
}

.SignUpCardInfoItem h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-800, #6c7360);
}

.SignUpCardInfoItem.SignUpCardInfoSingleItem {
  width: 100%;
}

.SignUpCardInfoItem:nth-child(even) {
  border-left: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
}

.SignUpCardInfoFooter {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding: 12px;
  align-items: center;
}

.SignUpCardInfoFooterText {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--Light-Mode-Neutral-neutral-900, #3d4837);
}

.SignUpCardInfoFooterStatus {
  font-family: Martian Mono;
  font-size: 11px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 16px 16px 0 16px;
}

.SignUpCardInfoFooterStatus.StatusActive {
  color: var(--Light-Mode-Teal-teal-800, #124832);
  background: var(--Light-Mode-Teal-teal-100, #baf1d4);
  border: 1px solid var(--Light-Mode-Teal-teal-900, #123722);
}

.SignUpStep6 {
  background: var(--Light-Mode-Lilac-lilac-400, #ffc3fe);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
}

.SignUpStep6 > div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}

.SignUpStepRadio .ant-radio-wrapper {
  border-radius: 4px;
  border: 1px solid var(--Light-Mode-Lilac-lilac-950, #263322);
  width: 164px;
  height: 188px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  padding: 8px;
}

.SignUpStepRadio .ant-space {
  flex-direction: row;
  justify-content: center;
}

.SignUpStepRadio .ant-radio-inner,
.SignUpStepRadio .ant-radio-input {
  display: none;
}

.SignUpStepRadioItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.SignUpStepRadioItem > svg {
  flex: 1;
}

.SignUpStepsContainer .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: var(--Light-Mode-Teal-teal-50, #ebfaf4);
  border-radius: 4px;
  border: 2px solid var(--Light-Mode-Teal-teal-500, #107960);
}

.SignUpStepQuestion {
  color: var(--Light-Mode-Lilac-lilac-800, #845284);
  font-family: Martian Mono;
  font-size: 11px;
  font-weight: 700;
  width: fit-content;
  cursor: pointer;
  display: flex;
  gap: 8px;
}

.SignUpStepBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 24px;
}

.SignUpStepBottomGrabberBar {
  background: var(--Light-Mode-Neutral-neutral-600, #9c9e88);
  width: 40px;
  height: 4px;
  border-radius: 100px;
}
