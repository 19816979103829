.ThreadFooterContainer {
  background-color: #faf4da;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #263322;
  align-content: center;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ThreadFooterAvatarContainer {
  width: 65px;
  gap: 8px;
  padding: 8px, 0px, 0px, 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.textarea-input {
  background-color: #fbfaf2;
}

.textarea-input-wrapper {
  background-color: #fbfaf2;
  border-color: #263322;
  border-width: 1px;
  border-radius: 6px;

  &:hover {
    border-color: #263322;
  }
}

.ReplyButton {
  background-color: #d9f564;
}
