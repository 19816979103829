.AnimationRight {
  position: absolute;
  left: 170px;
  width: 100%;
  height: 100%;
}

.AnimationLeft{
  position: absolute;
    right: 230px;
    width: 100%;
    height: 100%;
}