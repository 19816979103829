.EmptyStateContainerBox {
  background-color: #faf4da;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  border-color: #263322;
  border-width: 1px;
  border-radius: 16px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 40px;
  text-align: center;
}

.EmptyStateTitle {
  font-family: "Martian Mono", monospace;
  font-size: 12px;
  font-weight: 700;
  color: #6c7360;
}

.EmptyStateButton {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #FBFAF2;
  box-shadow: 2px 2px 0px 0px #263322;
  margin-bottom: 3px;
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
}

.EmptyStateButton:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}