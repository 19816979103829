.StartBrowsingContainer {
  padding: 12px;
  padding-top: 0;
  background-color: #FBFAF2;
  width: 100%;
  position: relative;
}

.StartBrowsingTitle {
  font-family: Moderat Mono Trial;
  font-size: 13px;
  font-weight: 700;
  color: #263322;
  padding-bottom: 12px;
}

.StartBrowsingCards {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.StartBrowsingCard {
  cursor: pointer;
  border: 1px solid #263322;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.StartBrowsingCard.StartBrowsingBreakroom {
  background: #FFC3FE;
}

.StartBrowsingCard.StartBrowsingLifestyle {
  background: #D9F564;
}

.StartBrowsingCard.StartBrowsingCareer {
  background: #9AECBF;
}

.StartBrowsingCardTitle {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 700;
  color: #1C1C1C;
  padding: 0px 16px 0px 20px;
}

.StartBrowsingCardDescriptionContainer {
  display: flex;
  flex-direction: row;
  padding: 0px 16px 10px 20px;
}

.StartBrowsingCardDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 500;
}

.StartBrowsingCardIcon {
  width: fit-content;
  height: fit-content;
  border: 1px solid #202C25;
  border-radius: 2px;
  transform: rotate(-45deg);
  padding: 4px;
  margin: 0 0 0 20px;
}

.StartBrowsingCardIconBreakroom {
  background-color: #FCEAFE;
}

.StartBrowsingCardIconLifestyle {
  background-color: #E8F8A6;
}

.StartBrowsingCardIconCareer {
  background-color: #EBFAF4;
}

.StartBrowsingCard img {
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  align-self: flex-end;
  /* height: 110px; */
}