.NavFooterDialogContainer {
  position: absolute;
  background-color: #FFC3FE;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-width: 1px;
  width: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 24px;
  border-color: #70406F;
  box-shadow: 3px 3px 0px 0px #5B2D5B;
  z-index: 10;
}

.NavFooterDialogWelcomeTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #263322;
}

.NavFooterDialogTitle {
  font-family: "Figtree", sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.NavFooterDialogReminderBtn {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  color: #70406F;
  cursor: pointer;
}

.NavFooterDialogStartBtn {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  color: #263322;
}

.NavFooterDialogStartBtnContainer {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #9AECBF;
  box-shadow: 1px 1px 0px 0px #263322;
  /* margin-bottom: 3px; */
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
  display: inline-block;
}

.NavFooterDialogStartBtnContainer:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}