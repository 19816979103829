.TitleHeaderContainer {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #263322;
  font-family: Figtree;
  font-size: 23px;
  font-weight: 600;
  color: #000000;
  align-items: flex-end;
  padding: 12px;
}