.ThreadHeaderContainer {
  display: flex;
  flex-direction: row;
  background-color: #faf7e8;
  border-bottom-width: 1px;
  border-bottom-color: #263322;
  width: 100%;
}

.ThreadHeaderTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right-width: 1px;
  border-right-color: #263322;
  overflow: hidden;
  padding-right: 15px;
  padding-left: 15px;
}

.ThreadHeaderDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
}

.ThreadHeaderTitle {
  color: #263322;
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  margin-left: 5px;
}

.ThreadHeaderBackgroundTitleContainer {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ThreadHeaderBackgroundTitle {
  position: absolute;
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-size: 20px;
  font-weight: 700;
  top: 0;
  left: 0;
  line-height: 24px;
  text-align: left;
  user-select: none;
  cursor: default;
}

.ThreadHeaderBackgroundTitle > span {
  opacity: 0.5;
}
