.DiscoverHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-bottom: 1px solid #263322;
}

.DiscoverHeaderContainer.DiscoverHeaderFocused {
  border-bottom: none;
}

.DiscoverHeaderName {
  font-family: Figtree;
  font-size: 23px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  padding: 12px;
  padding-bottom: 0;
}

.DiscoverHeaderSearch {
  padding: 12px;
  display: flex;
}

.DiscoverHeaderSearch .anticon {
  color: #263322;
  padding-right: 8px;
}

.DiscoverHeaderSearch input::placeholder {
  color: #3D4837;
}

.DiscoverHeaderSearch > span {
  background-color: #FBFAF2;
}

.DiscoverHeaderFocused .DiscoverHeaderSearch > span {
  border-radius: 12px 0 0 12px;
}

.DiscoverHeaderSearch > button {
  background-color: #9AECBF !important;
  border-radius: 0 12px 12px 0;
  font-family: Martian Mono;
  font-size: 11px;
  font-weight: 700;
}

.DiscoverHeaderFocused {
  background-color: #FED6FE;
}

.DiscoverHeaderFocused .DiscoverHeaderSearch {
  padding-top: 48px;
}

.DiscoverHeaderRecentSearchTitle {
  background-color: #FDE0FE;
  border-bottom: 1px solid #5B2D5B;
  border-top: 1px solid #5B2D5B;
  display: flex;
  align-items: center;
  font-family: Moderat Mono Trial;
  font-size: 14px;
  font-weight: 700;
  color: #5B2D5B;
  padding: 16px 12px;
}

.DiscoverHeaderRecentSearch {
  font-family: Moderat Mono Trial;
  font-size: 14px;
  font-weight: 700;
  color: #845284;
  background-color: #FCEAFE;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #5B2D5B;
}

.DiscoverHeaderRecentSearch > div {
  padding: 20px 16px;
}