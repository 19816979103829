.FollowButton2 {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #fbfaf2;
  box-shadow: 2px 2px 0px 0px #263322;
  margin-bottom: 3px;
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
  display: inline-block;
}

.FollowButton2:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}

.FollowActived {
  box-shadow: 0px 0px 0px 0px;
  background-color: #263322;
  color: #fbfdfe;
}
