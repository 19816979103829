.PageHeader {
  background-color: #faf7e8;
  padding-left: 12px;
  padding-top: 24px;
  padding-bottom: 12px;

  border-bottom-width: 1px;
  border-color: #263322;
}

@media (max-width: 720px) {
  .PageHeader {
    display: flex;
    justify-content: center;
    border-bottom-width: 0;
  }
}
