.CreatePostContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  width: 100%;
  padding-bottom: 50px;
  overflow: hidden;
}

.CreatePostInputContainer input::placeholder {
  color: #9c9e88;
}

.CreatePostContainerBody {
  overflow: auto;
}

.CreatePostGuidelineToast {
  background-color: #EBFAF4;
  /* gap: 18px; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  margin-bottom: 14px;
}

.CreatePostGuidelineToastMessage {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #115841;
}

.CreatePostInputTitle {
  /* font-family: 'Oatmeal Pro TRIAL'; */
  font-family: "Figtree", sans-serif;
  font-size: 26px;
  font-weight: 600;
  padding-top: 16px;
  color: #263322;
  /* set placeholder color */
  ::placeholder {
    color: #9c9e88;
  }
  background-color: #fcfcfe;
}

.CreatePostInputDescription {
  /* font-family: 'Oatmeal Pro TRIAL'; */
  font-family: "Figtree", sans-serif;
  font-size: 16px;
  font-weight: 500;
  /* padding-top: 16px; */
  color: #9c9e88;
  background-color: #fcfcfe;
  max-height: 200px;
  overflow: auto;
}

.CreatePostButtonContainer {
  position: fixed;
  bottom: 24px;
  right: 16px;
  border: #263322 1px solid;
  height: 62px;
  border-radius: 30px 30px 0 30px;
  background-color: #d9f564;
  font-family: "Moderat Mono Trial";
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
  color: #000000;
  cursor: pointer;
  box-shadow: 2px 2px 0px 0px #107960;
  display: flex;
  overflow: hidden;
}

.CreatePostPostingText {
  /* fontFamily: 'Moderat Trial', */
  font-family: "Figtree", sans-serif;

  font-size: 13px;
  font-weight: 450;
  color: #3d4837;
}

.UploadImageFlexDiv {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  position: relative;
}

.UploadImageDiv {
  position: relative;
}

.UploadImageDiv button {
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0.7;
}

.UploadImage {
  max-width: calc(100vw - 24px);
  object-fit: contain;
  max-height: 400px;
  border-radius: 20px;
}

.CreatePostRichTextContainer {
  position: fixed;
  width: 100vw;
  height: 50px;
  background: rgb(250, 247, 232);
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-top: 1px solid #263322;
  gap: 25px;
  display: flex;
  align-items: center;
}

.CreatePostInputDescriptionPlaceholder {
  position: relative;
  /* background-color: pink; */
}

.CreatePostButtonSubmit {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #263322;
}