.PasswordStrengthSteps {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.PasswordStrengthStep {
  flex: 1;
  height: 4px;
  background: var(--Light-Mode-Neutral-neutral-500, #B3B39D);
}

.PasswordStrengthStep.PasswordStrengthStepActived1 {
  background: var(--Light-Mode-Neutral-neutral-950, #263322);
}

.PasswordStrengthStep.PasswordStrengthStepActived2 {
  background: var(--Light-Mode-Neutral-neutral-950, #FFA500);
}

.PasswordStrengthStep.PasswordStrengthStepActived3 {
  background: var(--Light-Mode-Neutral-neutral-950, #1E90FF);
}

.PasswordStrengthStep.PasswordStrengthStepActived4 {
  background: var(--Light-Mode-Neutral-neutral-950, #028A0F);
}

.PasswordStrengthLogIn {
  display: flex;
  justify-content: space-between;
}

.PasswordStrengthCaretLeft {
  font-size: 16px;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  cursor: pointer;
}

.PasswordStrengthLogIn > span {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.PasswordStrengthLogIn > span > a {
  color: var(--Light-Mode-Lilac-lilac-800, #845284);
}

.PasswordStrengthValidate {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #263322;
}