.SignUpHeaderSteps {
  display: flex;
}

.SignUpHeaderStep {
  flex: 1;
  height: 4px;
  background: var(--Light-Mode-Neutral-neutral-150, #FAF7E6);
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: var(--Light-Mode-Neutral-neutral-950, #263322);
}

.SignUpHeaderStep.SignUpHeaderStepActived {
  background: var(--Light-Mode-Teal-teal-150, #9AECBF);
}

.SignUpHeaderLogIn {
  display: flex;
  justify-content: space-between;
}

.SignUpHeaderCaretLeft {
  font-size: 16px;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  cursor: pointer;
}

.SignUpHeaderCardHeaderContainer {
  background-color: '#FAF7E6';
  display: flex;
  width: 100%;
  height: 88px;
}

.SignUpHeaderCardClose {
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 14px;
  border-right: 1px solid #263322;
  border-bottom: 1px solid #263322;
  background-color: #FAF7E6;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #263322;
}

.SignUpHeaderCardClose > div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.SignUpHeaderCardTitleDiv {
  flex: 1;
  border-bottom: 1px solid #263322;
  position: relative;
  overflow: hidden;
}

.SignUpHeaderCardBackgroundTitle {
  position: absolute;
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-size: 22px;
  font-weight: 700;
  top: 0;
  line-height: 28px;
  background-color: #FAF7E6;
}

.SignUpHeaderCardBackgroundTitle > span {
  opacity: 0.5;
  color: #E2DEC6;
}