.SignUpFooterContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;
  margin: auto;
  padding: 0 24px 24px 24px;
}

.SignUpFooterLinkedin {
  font-size: 24px;
  color: #fbfdfe;
}

.SignUpFooterText {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #263322;
}

.SignUpFooterText > a {
  font-weight: 600;
  color: #845284;
  cursor: pointer;
}

.SignUpFooterLogIn {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.SignUpFooterLogIn a {
  color: var(--Light-Mode-Lilac-lilac-800, #845284);
}
