.PostFooterContainer {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-color: black;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 720px) {
  .max-width-1024-border-r-0 {
    border-right-width: 0px;
  }

  .max-width-1024-border-l-0 {
    border-left-width: 0px;
  }
}
