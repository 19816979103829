.SelectAccordion button {
  background-color: #FCF3FE !important;
  border-radius: 0;
  padding: 12px 12px 12px 0;
}

.SelectAccordion {
  padding: 0 !important;
}

.SelectAccordionTag {
  border: #263322 1px solid;
  padding: 2px 16px;
  border-radius: 20px 20px 0 20px;
  background-color: #FECDFE;
  font-family: "Work Sans", sans-serif;
  font-size: 11px;
  font-weight: 700;
  width: fit-content;
  color: #5B2D5B;
  margin-left: 32px;
  background-color: #FECDFE;
}

.SelectAccordionPlaceholder {
  padding: 3px 32px;
  color: #845284;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.SelectAccordionItem {
  background-color: #FCF3FE;
  border-bottom: 1px solid #5B2D5B;
  padding: 12px 0;
  color: #845284;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  height: 58px;
  align-items: center;
  cursor: pointer;
}

.SelectAccordionAccordionItem section > div {
  padding: 0;
  border-top: 1px solid #5B2D5B;
}

.SelectAccordionCheck {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectAccordionChecked {
  background-color: #FDE0FE;
}