.PostHeaderContainer {
  background-color: #faf7e8;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.PostHeaderAvatar {
  width: 64px;
  border-top-width: 0px;
  border-color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 8px 0px 0px 0px;
  background-color: #fbfdfe;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 9;
}

.PostHeaderAvatarBorderTop {
  width: 65px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #fbfdfe;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  z-index: 10;
}

.PostHeaderBorderLeftWindow {
  border-left-width: 1px;
  border-left-color: black;
}

.PostHeaderBorderRightWindow {
  border-right-width: 1px;
  border-right-color: black;
}

.PostHeaderNameContainer {
  display: flex;
  border-radius: 0;
  margin-top: 10px;
  border-color: black;
  background-color: #ecfabc;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding-top: 6px;
  padding-left: 12px;
  padding-bottom: 6px;
  padding-right: 12px;

  display: flex;
  justify-content: space-between;
}

.PostHeaderDescriptionContainer {
  background-color: #fbfaf2;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: black;
  border-top-width: 0px;
  padding-top: 4px;
  padding-left: 12px;
  padding-bottom: 4px;
  padding-right: 8px;
  display: flex;
  height: 28px;
}

.PostHeaderTitle {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  align-self: center;
}

.PostHeaderLetters {
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.PostHeaderDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

@media (max-width: 720px) {
  .PostHeaderAvatarBorderTop {
    width: 65px;
  }
  .PostHeaderBorderLeftWindow {
    border-left-width: 0px;
  }
  .PostHeaderBorderRightWindow {
    border-right-width: 0px;
  }

  .PostHeaderDescriptionContainer {
    border-right-width: 0px;
  }

  .PostHeaderNameContainer {
    border-right-width: 0px;
  }
}
