.TrendingContainer {
  padding: 12px;
  background-color: #FBFAF2;
  width: 100%;
  position: relative;
}

.TrendingTitle {
  font-family: Moderat Mono Trial;
  font-size: 13px;
  font-weight: 700;
  color: #263322;
  padding-bottom: 12px;
}

.TrendingCards {
  position: absolute;
  height: 300px;
  width: 600px;
  top: 60px;
  top: 10px;
  display: flex;
  flex-direction: column;
}

.TrendingCard {
  position: relative;
  /* width: calc(100vw - 30px); */
  width: 100%;
  border-radius: 10px;
  transition: 0.2s ease-out;
  background-color: transparent;
  height: 246px;
}

.TrendingCardHeader {
  padding: 6px 16px;
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 700;
  color: #263322;
  border: 1px solid #263322;
  border-bottom: none;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px 16px 0 0;
}

.lifestyle .TrendingCardHeader {
  background-color: #D9F564;
}

.career .TrendingCardHeader {
  background-color: #FFC3FE;
}

.wellness .TrendingCardHeader {
  background-color: #9AECBF;
}

.TrendingCardDate {
  display: flex;
  padding: 6px 16px;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #263322;
  align-items: center;
  gap: 8px;
  border: 1px solid #263322;
  border-top: none;
  border-bottom: none;
}

.lifestyle .TrendingCardDate {
  background-color: #F1FBD2;
}

.career .TrendingCardDate {
  background-color: #FCEAFE;
}

.wellness .TrendingCardDate {
  background-color: #EBFAF4;
}

.TrendingCardBody {
  padding: 24px 16px;
  border: 1px solid #263322;
  background-color: #FBFAF2;
  max-height: 143px;
  overflow: auto;
}

.TrendingCardBodyTitle {
  font-family: Figtree;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 12px;
  color: #263322;
}

.TrendingCardBodyMessage {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #263322;
}

.EmptyTrending {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  background-color: #FDE0FE;
  height: 246px;
  border: 1px solid #263322;
  border-radius: 16px 16px 4px 16px;
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  width: 100%;
  gap: 12px;
}

.EmptyTrendingBtns {
  display: flex;
  gap: 12px;
  padding-top: 20px;
}

.EmptyTrendingBtns button {
  border: 1px solid #263322;
  font-family: Martian Mono;
  font-size: 11px;
  font-weight: 700;
  color: #263322;
  border-radius: 2px;
}

.EmptyTrendingStart {
  box-shadow: 2px 2px 0px 0px #329678;
  background-color: #FBFAF2;
}

.container {
  background: lightblue;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.deck {
  position: absolute;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  width: 100%;
}

.deck > div {
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  will-change: transform;
  border-radius: 10px;
  width: 100%;
}