@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Martian+Mono:wght@100..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background-color: #faf7e8;
  z-index: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

/* @media only screen and (max-width: 600px) {
  body {
    position: fixed;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --vh: 1vh;
}

html.is-safari,
html.is-safari body {
  height: calc(var(--vh, 1vh) * 100);
}

.bg-rubie-green {
  background-color: #d9f564;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: underline;
}

.raf-loading-indicator {
  width: 100vw;
  max-width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
