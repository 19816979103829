.RubieButton {
  font-family: 'Martian Mono';
  font-size: 16px;
  font-weight: 400;
  border-radius: 40px;
  color: #263322;
  background-color: #D9F564;
  border: 1px solid var(--Light-Mode-Neutral-neutral-950, #263322);
}

.RubieButton.bg-transparent {
  color: #263322;
  background-color: transparent;
}

.RubieButton.bg-primary {
  color: #FBFDFE;
  background-color: #263322;
  border: 1px solid var(--Light-Mode-Lilac-lilac-950, #5B2D5B);
}