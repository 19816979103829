.InviteContainerBox {
  /* background-color: #faf4da; */
  display: flex;
  flex-direction: column;
  /* gap: 30px; */
  justify-content: center;
  align-items: center;
  border-color: #263322;
  border-width: 1px;
  border-radius: 16px;
  /* margin: auto; */
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding: 20px; */
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  padding-bottom: 10px;
}

.InviteButtonContainer{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  align-items: center;
}

.InviteEarnMoreButton {
  border-width: 1px;
  border-radius: 100px;
  border-color: #263322;
  background-color: #FBFAF2;
  box-shadow: 2px 2px 0px 0px #263322;
  /* margin-bottom: 3px; */
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  font-family: "Martian Mono", monospace;
  /* padding: 6px 12px 6px 12px; */
  height: 2rem;
}

.InviteCountButton {
  background-color: #FBFDFE !important;
  height: 1.5rem !important;;
}

.InviteImageContainer{
  /* background-color: #E2DEC6; */
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  min-height: 280px;
}

.InviteBackground {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
}

.TicketContainer{
  justify-content: center;
  display: flex;
  z-index: 1;
  position: relative;
}

.InviteTitle {
  font-family: "Figtree", sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.InviteDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3D4837;
}

.CopyButton {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #FBFAF2;
  box-shadow: 2px 2px 0px 0px #263322;
  margin-bottom: 3px;
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
  height: 40px;
}

.CopyButton:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}

.InviteTrackerTitle {
  font-family: "Figtree", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #263322;
}

.InviteTrackerContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.TrackerInviteItemContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px;
  border-width: 1px;
  border-color: #263322;
  background-color: #FCF3FE;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.TrackerInviteDescriptionContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.TrackerInviteName {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 700;
  color: #263322;
}

.TrackerInviteDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #6C7360;
}

.UnlockInviteDrawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 24px;
}

.UnlockInviteDrawerGrabberBar {
  background: var(--Light-Mode-Neutral-neutral-600, #9C9E88);
  width: 40px;
  height: 4px;
  border-radius: 100px;
}

.UnlockInviteDrawerFlex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.UnlockInviteDrawerTitle {
  font-family: Figtree;
  font-size: 23px;
  font-weight: 700;
  color: #263322;
}

.UnlockInviteDrawerItems {
  border: 1px solid var(--Light-Mode-Neutral-neutral-800, #6C7360);
  border-radius: 8px;
}

.UnlockInviteDrawerItem {
  display: flex;
  gap: 12px;
  padding: 16px;
}

.UnlockInviteDrawerItem:not(:last-child) {
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-800, #6C7360);
}

.UnlockInviteDrawerItemContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.UnlockInviteDrawerItemIcon {
  font-size: 24px;
}

.UnlockInviteDrawerItemTitle {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 700;
  color: #263322;
}

.UnlockInviteDrawerItemDescription {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-900, #3D4837);
  text-align: left;
}

.UnlockInviteDrawerItemInfo {
  font-family: Martian Mono;
  font-size: 14px;
  font-weight: 400;
  color: var(--Light-Mode-Neutral-neutral-800, #6C7360);
}
