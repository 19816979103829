.MenuFooterContainer {
  background-color: #faf7e8;
  width: 100%;
  border-top-width: 1px;
  border-top-color: black;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.MenuCreatePostLink {
  width: 60px;
  height: 65px;
  border-radius: 20px;
  border-width: 1px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  background-color: #d9f564;
  border-color: #263322;
  filter: drop-shadow(1px 1px 0 0);
  margin-bottom: 20px;
  overflow: visible;
}
