.ProfileHeaderContainer {
  background-color: #fceafe;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #263322;
  /* padding: 20px; */

  border-right: 1px solid #263322;
  border-left: 1px solid #263322;
}

.ProfileHeaderCardTitleDiv {
  flex: 1;
  border-bottom: 1px solid #263322;
  position: relative;
}

.ProfileAvatar {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 10px;
}

.ProfileHeaderTitle {
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
  height: 100%;
  color: #263322;
  font-family: "Figtree", sans-serif;

  font-size: 25px;
  font-weight: 600;
  padding: 0 0 0 12px;
}

.ProfileHeaderCardBackgroundTitle > span {
  opacity: 0.5;
  color: #ffc3fe;
  font-size: 23px;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
}

.ProfileHeaderCardBackgroundTitle {
  /* position: absolute; */
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-weight: 600;
  top: 0;
  line-height: 25px;
  background-color: #faf7e603;
  height: 100px;
  overflow: hidden;
}

.ProfileHeaderContainerBackgroundTitle {
  position: absolute;
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-size: 30px;
  font-weight: 700;
  top: 0;
  line-height: 33px;
  background-color: #faf7e6;
  height: 100px;
}

@media (max-width: 720px) {
  .ProfileHeaderContainer {
    border-left-width: 0px;
    border-right-width: 0px;
  }
}

.ProfileHeaderName {
  font-family: Figtree;
  font-size: 29px;
  font-weight: 600;
  color: #263322;
  padding-top: 10px;
}

.ProfileHeaderTextContainer {
  display: flex;
  flex-direction: column;
  background-color: #fbfaf2;
}

.ProfileHeaderDescription {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding: 0 0 12px 12px;
}

.ProfileHeaderCardHeaderContainer {
  background-color: "#FAF7E6";
  display: flex;
  width: 100%;
  height: 88px;
}

.ProfileHeaderCardClose {
  width: 136px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 14px;
  border-right: 1px solid #263322;
  border-bottom: 1px solid #263322;
  background-color: #faf7e6;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #263322;
}

.ProfileHeaderCaretLeft {
  font-size: 16px;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  cursor: pointer;
}

.SignUpHeaderCardTitleDiv {
  flex: 1;
  border-bottom: 1px solid #263322;
  position: relative;
  overflow: hidden;
}
