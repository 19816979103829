.TabsContainer {
  border-radius: 8px;
  border-width: 1px;
  border-color: #263322;
  background-color: #E2DEC6;
}

.Tab {
  font-family: "Martian Mono", monospace;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.SelectedTab {
  font-weight: 600;
}

.CursorContainer {
  background-color: #FBFDFE;
  border-width: 1px;
  border-radius: 4px;
  border-color: #263322;
  height: 90%;
  top: 5%
}