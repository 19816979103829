.WelcomeContainer {
  background-color: #faf7e8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.WelcomeFooter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding: 16px;
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 500;
}

.WelcomeFooterText {
  color: #848874;
  text-align: center;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: Martian Mono, sans-serif;
  font-size: 10px;
  line-height: 11.5px;
}

a.WelcomeFooterText {
  text-decoration: underline;
  text-transform: uppercase;
}

.CopyRight {
  color: #848874;
  text-align: center;
  letter-spacing: 0;
  text-transform: none;
  font-family: "Work Sans", sans-serif;
  font-size: 9px;
  font-weight: 600;
}

.WelcomeTitle {
  font-family: "Figtree";
  font-size: 36px;
  font-weight: 600px;
  color: #263322;
  width: 300px;
}

.WelcomeDescriptionText {
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  color: #263322;
  width: 300px;
}
