.CreatePostHeaderContainer {
  background-color: "#FAF7E8";
  display: flex;
  width: 100%;
  height: 126px;
}

.CreatePostClose {
  width: 74px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 26px;
  border-right: 1px solid #263322;
  border-bottom: 1px solid #263322;

  svg {
    cursor: pointer;
  }
}

.CreatePostTitleDiv {
  flex: 1;
  border-bottom: 1px solid #263322;
  position: relative;
  overflow: hidden;
}

.CreatePostBackgroundTitle {
  position: absolute;
  word-break: break-all;
  font-family: "Martian Mono", monospace;
  font-size: 23px;
  font-weight: 700;
  top: 0;
  line-height: 36px;
}

.CreatePostBackgroundTitle > span {
  opacity: 0.3;
}

.CreatePostTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  color: #263322;
  z-index: 1;
  font-family: "Martian Mono", monospace;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  padding-bottom: 17px;

  span {
    padding: 0 10px 0 12px;
    z-index: 1;
    background-color: transparent;
  }
}

/* .rsw-btn {
  background: none !important;
}

.rsw-btn[data-active=true] {
  background: #e0e0e0 !important;
}

.rsw-btn:hover[data-active=true] {
  background: #e0e0e0 !important;
} */
