.SettingsContainer {
  background: var(--Light-Mode-Neutral-neutral-100, #fbfaf2);
  min-width: 100vw;
}

.SettingsContainer .ant-switch-handle::before,
.SettingsContainer .ant-switch-inner {
  border: 1px solid #263322;
}

.SettingsContent {
  padding: 48px 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-900, #3d4837);
}

.SettingsUserName {
  font-family: Figtree;
  font-size: 29px;
  font-weight: 600;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  padding-top: 16px;
}

.SettingsUserId {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-800, #6c7360);
}

.SettingsItems {
  display: flex;
  flex-direction: column;
  background: var(--Light-Mode-Neutral-neutral-150, #faf7e6);
}

.SettingsItem {
  cursor: pointer;
  display: flex;
  font-family: Martian Mono;
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  gap: 12px;
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-900, #3d4837);
  align-items: center;
  justify-content: center;
}

.SettingsItemLast {
  border-bottom: 0px;
}

.SettingsItemDescription {
  flex: 1;
}

.SettingsItem svg {
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.SettingsItemPlus {
  font-size: 16px;
}

.SettingsButtons {
  gap: 12px;
  display: flex;
  justify-content: flex-end;
}

.SettingsStepRadioItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
}

.SettingsStepRadioItem > svg {
  flex: 1;
  font-size: 40px;
  padding-bottom: 24px;
}

.SettingsStepRadio .ant-radio-wrapper {
  border-radius: 4px;
  border: 2px solid var(--Light-Mode-Lilac-lilac-950, #263322);
  width: 164px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
  padding: 8px;
}

.SettingsStepRadio .ant-space {
  flex-direction: row;
  justify-content: center;
}

.SettingsStepRadio .ant-radio-inner,
.SettingsStepRadio .ant-radio-input {
  display: none;
}

.SettingsStepRadio {
  justify-content: center;
}

.SettingsStepRadioItemTitle {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-950, #263322);
}

.SettingsStepRadioItemDescription {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-800, #6c7360);
  text-align: center;
}

.ant-radio-wrapper.ant-radio-wrapper-checked {
  background: var(--Light-Mode-Teal-teal-50, #ebfaf4);
  border-radius: 4px;
  border: 2px solid var(--Light-Mode-Teal-teal-500, #107960);
}

.SettingsCards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 370px;
}

.SettingsCard {
  border: 1px solid var(--Light-Mode-Neutral-neutral-800, #6c7360);
  border-radius: 8px;
  width: 100%;
}

.SettingsCardTitle {
  padding: 12px 16px;
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 700;
}

.SettingsCardItem {
  padding: 14px 16px;
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-800, #6c7360);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
}

.SettingsCard > div:last-child {
  border-bottom: none;
}

.SettingsCardDescription {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  color: var(--Light-Mode-Neutral-neutral-800, #6c7360);
  padding: 14px 16px;
  border-bottom: 1px solid var(--Light-Mode-Neutral-neutral-800, #6c7360);
}

.SettingsCardDescription > a {
  color: var(--Light-Mode-Teal-teal-150, #9aecbf);
}

.SettingsCardAccount svg {
  font-size: 20px;
}

.SettingsCardAccount {
  display: flex;
  gap: 12px;
  align-items: center;
}

.SettingsCardConnected {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  color: var(--Light-Mode-Teal-teal-150, #9aecbf);
}

.SettingsCardConnect {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 600;
  color: var(--Light-Mode-Lilac-lilac-800, #845284);
}
