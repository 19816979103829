.PostContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 720px;
  position: relative;
  width: 100%;
}

.PostBorderWindow {
  border-left-width: 1px;
  border-left-color: black;
  border-right-width: 1px;
  border-right-color: black;
}

.PostBody {
  padding-top: 24px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.PostTitle {
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-size: 23px;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

.PostContent {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.PostDate {
  font-family: "Work Sans", sans-serif;
  text-align: left;
  align-self: flex-start;
  font-weight: 500;
  font-size: 13px;
  margin-top: 8px;
  color: #848874;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.ThreadBirdContainerBox {
  background-color: #fbfdfe;
  display: flex;
  width: 369px;
  height: 80vh;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  border-color: #263322;
  border-width: 1px;
  border-radius: 16px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 40px;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center;
}

.WaitingCommunityText {
  font-family: "Martian Mono", monospace;
  font-size: 12px;
  font-weight: 700;
  color: #6c7360;
}

.PostImageLoading {
  width: 100%;
  height: 300px;
  background-color: white;
}

.PostImageContainer {
  width: 100%;
  max-height: 300px;
  background-size: cover;
  background-position: center;
  border-top: 1px solid #263322;
  object-fit: cover;
  background-color: white;
}

.PostImageContainerDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 720px) {
  .PostBorderWindow {
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }

  .PostImageContainer {
    width: 100% !important;
  }
}

.EmptyPostButton {
  border-width: 1px;
  border-radius: 2px;
  border-color: #263322;
  background-color: #d9f564;
  box-shadow: 1px 1px 0px 0px #263322;
  /* margin-bottom: 3px; */
  color: #263322;
  font-size: 11px;
  font-weight: 700;
  font-family: "Martian Mono", monospace;
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto !important;
}

.EmptyPostButton:hover {
  color: #263322 !important;
  border-color: #263322 !important;
}
