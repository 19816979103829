.AvatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvatarName {
  position: absolute;
  color: #263322;
  font-family: Figtree;
  font-weight: 700;
}